import React, { useState, useCallback } from "react"

import { Card, TextStyle, TextContainer, Modal, List, DatePicker, Banner } from "@shopify/polaris"

export const PaymentPlanDateCard = ({ plan, onUpdateDate, isUpdating }) => {
  let date = new Date()
  const billingDayOfMonth = plan.subscription?.billingDayOfMonth
  if (billingDayOfMonth && billingDayOfMonth >= date.getDate()) {
    date.setDate(billingDayOfMonth)
  } else if (billingDayOfMonth && billingDayOfMonth < date.getDate()) {
    if (date.getMonth() === 11) {
      date = new Date(date.getFullYear() + 1, 0, 1)
    } else {
      date = new Date(date.getFullYear(), date.getMonth() + 1, 1)
    }
    date.setDate(billingDayOfMonth)
  }

  const [{ month, year }, setDate] = useState({ month: date.getMonth(), year: date.getFullYear() })
  const [selectedDates, setSelectedDates] = useState({
    start: date,
    end: date,
  })

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), [])

  const [modalActive, setModalActive] = useState(false)

  const handleModalChange = useCallback(() => setModalActive(false), [])

  const handleClickChangeDate = () => {
    setModalActive(true)
  }

  const handleChangePaymentDate = () => {
    const paymentDate = selectedDates.start.getDate()

    onUpdateDate({ paymentDate }).then(res => {
      setModalActive(false)
    })
  }

  const getOrdinalSuffix = int => {
    var j = int % 10,
      k = int % 100
    if (j == 1 && k != 11) {
      return int + "st"
    }
    if (j == 2 && k != 12) {
      return int + "nd"
    }
    if (j == 3 && k != 13) {
      return int + "rd"
    }
    return int + "th"
  }

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Card
          secondaryFooterActions={[
            {
              content: "Reset",
              onAction: () => {
                setSelectedDates({
                  start: date,
                  end: date,
                })
              },
              disabled: selectedDates.start === date,
            },
          ]}
          primaryFooterAction={{
            content: "Change",
            onAction: handleClickChangeDate,
            loading: isUpdating,
          }}
        >
          <Card.Header title="Change Payment Date"></Card.Header>
          <Card.Section>
            <TextContainer>
              {
                "Due to the Braintree API limit, the selected date must be 1-28 or 31. Both 29 and 30 will be automatically converted to 31, while the value of 31 tells the recurring billing engine to charge the subscription on the last day of the month, regardless of how many days are in the month."
              }
            </TextContainer>
          </Card.Section>
          <Card.Section>
            <DatePicker
              month={month}
              year={year}
              onChange={setSelectedDates}
              onMonthChange={handleMonthChange}
              selected={selectedDates}
              disableDatesBefore={new Date()}
            />
          </Card.Section>
        </Card>
      </div>

      <Modal
        open={modalActive}
        onClose={handleModalChange}
        title="Change Payment Date?"
        primaryAction={{
          content: "Continue",
          onAction: handleChangePaymentDate,
          loading: isUpdating,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleModalChange,
          },
        ]}
      >
        <Modal.Section>
          <Card.Section>
            <TextContainer>
              <TextStyle>{`The new payment date will be ${
                selectedDates.start.getDate() > 28 ? "the end" : getOrdinalSuffix(selectedDates.start.getDate())
              } of each month.`}</TextStyle>
            </TextContainer>
          </Card.Section>
          <Card.Section>
            <Banner title="Please note this change will:" status="warning">
              <List>
                <List.Item>{`Cancel the current Braintree subscription.`}</List.Item>
                <List.Item>{`Create a new Braintree subscription with the selected start date.`}</List.Item>
              </List>
            </Banner>
          </Card.Section>
        </Modal.Section>
      </Modal>
    </>
  )
}
