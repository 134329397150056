import React, { useState, useCallback } from "react"

import { Card, FormLayout, TextField, Select, InlineError, TextStyle, TextContainer, Modal, List } from "@shopify/polaris"

export const PaymentPlanBillingCycleCard = ({ plan, onUpdateBillingCycle, isUpdating }) => {
  const [modalActive, setModalActive] = useState(false)
  const [payments, setPayments] = useState("")
  const [paymentsError, setPaymentsError] = useState(false)
  const [paymentsErrorMessage, setPaymentsErrorMessage] = useState(null)
  const [planId, setPlanId] = useState("")
  const [planIdError, setPlanIdError] = useState(false)

  const handleModalChange = useCallback(() => setModalActive(false), [])

  const handlePaymentsChange = useCallback(value => {
    if (!isNaN(value)) {
      setPayments(value)
    }

    if (parseInt(value) > 6 || parseInt(value) < 1) {
      setPaymentsError(true)
      setPaymentsErrorMessage(`Must be a value between 1 and 6`)
    } else {
      setPaymentsError(false)
      setPaymentsErrorMessage(null)
    }
  }, [])

  const handlePlanIdUpdate = useCallback(value => {
    setPlanIdError(false)
    setPlanId(value)
  }, [])

  const handleClickChangeBillingCycle = () => {
    let hasErrors = false
    if (!payments || isNaN(payments) || payments === "") {
      hasErrors = true
      setPaymentsError(true)
    }

    if (planId === "") {
      hasErrors = true
      setPlanIdError(true)
    }

    if (hasErrors) {
      return false
    }

    setModalActive(true)
  }

  const handleChangeBillingCycle = () => {
    onUpdateBillingCycle({ newPlanId: planId, newNumberOfBillingCycle: payments }).then(res => {
      setModalActive(false)
    })
  }

  const getOptions = () => {
    return plan.availablePlans.map(item => ({ label: item.planId, value: item.planId }))
  }

  const getHelpMessage = () => {
    if (payments && planId) {
      return `The remaining amount will be split into ${payments} payment${parseInt(payments) !== 1 ? "s" : ""}`
    }

    return null
  }

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Card
          primaryFooterAction={{
            content: "Change",
            onAction: handleClickChangeBillingCycle,
            loading: isUpdating,
          }}
        >
          <Card.Header title="Change Payment Plan"></Card.Header>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  id={`selectPlanField`}
                  label="New payment plan"
                  placeholder="Select payment plan"
                  options={getOptions()}
                  value={planId}
                  onChange={handlePlanIdUpdate}
                  error={planIdError}
                />
                <TextField
                  id={`paymentsInputField`}
                  error={paymentsError}
                  type="number"
                  label="Number of payments"
                  value={payments}
                  onChange={handlePaymentsChange}
                  helpText={paymentsErrorMessage ? <InlineError message={paymentsErrorMessage} fieldID="paymentsInputField" /> : null}
                  autoComplete="off"
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
        </Card>
      </div>

      <Modal
        open={modalActive}
        onClose={handleModalChange}
        title="Change payment plan?"
        primaryAction={{
          content: "Continue",
          onAction: handleChangeBillingCycle,
          loading: isUpdating,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleModalChange,
          },
        ]}
      >
        <Modal.Section>
          <Card.Section title="Change payment plan">
            <List>
              <List.Item>{getHelpMessage()}</List.Item>
            </List>
          </Card.Section>
          <Card.Section>
            <TextContainer>
              <TextStyle variation="subdued">{`Note: You may not be able to edit the plan if the plan status is either Past Due, Completed, or Cancelled.`}</TextStyle>
            </TextContainer>
          </Card.Section>
        </Modal.Section>
      </Modal>
    </>
  )
}
