import React from "react"

import { Card, ResourceList, Stack, Badge } from "@shopify/polaris"

export const PaymentPlanBalanceCard = ({ plan }) => {
  const statusMappings = {
    Active: {
      status: "success",
      text: "Active",
    },
    Expired: {
      status: "complete",
      text: "Completed",
    },
    Canceled: {
      status: "incomplete",
      text: "Cancelled",
    },
  }

  const totalSalesBreakdownItems = [
    {
      sales: "Total",
      amount: `$${plan.total}`,
    },
    {
      sales: "Outstanding",
      amount: `$${plan.grossOutstanding}`,
    },
    {
      sales: "Received Amount",
      amount: `$${(parseFloat(plan.total) - parseFloat(plan.outstanding)).toFixed(2)}`,
    },
    {
      sales: "Status",
      amount: (
        <Badge status={statusMappings[plan.status] ? statusMappings[plan.status].status : "info"}>
          {statusMappings[plan.status] ? statusMappings[plan.status].text : plan.status}
        </Badge>
      ),
    },
  ]

  return (
    <div style={{ marginBottom: 20 }}>
      <Card>
        <Card.Header title="Balance"></Card.Header>
        <Card.Section>
          <ResourceList
            resourceName={{ singular: "sale", plural: "sales" }}
            items={totalSalesBreakdownItems}
            renderItem={item => {
              const { sales, amount } = item
              return (
                <ResourceList.Item>
                  <Stack>
                    <Stack.Item fill>{sales}</Stack.Item>
                    <Stack.Item>{amount}</Stack.Item>
                  </Stack>
                </ResourceList.Item>
              )
            }}
          />
        </Card.Section>
      </Card>
    </div>
  )
}
