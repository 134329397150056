import React, { useState, useEffect } from "react"
import { ReactifyApp } from "@reactify-apps/reactify-core-app"

import { reverseShopifyAdminId } from "../../helpers/utils"

import { Card, ResourceList, EmptyState } from "@shopify/polaris"

import { LogsItem } from "../Logs/LogsItem"
import { LogsPagination } from "../Logs/LogsPagination"

export const PaymentPlanActivityCard = ({ plan, firebase, shop }) => {
  const pageSize = 5

  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [initialDoc, setInitialDoc] = useState(false)
  const [firstDoc, setFirstDoc] = useState(false)
  const [lastDoc, setLastDoc] = useState(false)
  const [showPrev, setPrev] = useState(false)
  const [showNext, setNext] = useState(true)
  const [sortField, setSortField] = useState("createdAt")
  const [sortDirection, setSortDirection] = useState("desc")

  const handleData = (snapshot, initial = false) => {
    const length = snapshot.docs.length
    if (length) {
      setItems(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
      if (initial) setInitialDoc(snapshot.docs[0].id)
      setFirstDoc(snapshot.docs[0].data().createdAt)
      setLastDoc(snapshot.docs[length - 1].data().createdAt)
      setPrev(!initial && initialDoc !== snapshot.docs[0].id)
      setNext(length === pageSize)
    } else {
      setNext(false)
    }
    setLoading(false)
  }

  const handleQuery = (prev = false, next = false) => {
    setLoading(true)
    ReactifyApp.Firebase.queryFirestore(
      firebase,
      `shops/${shop}/logs`,
      prev ? firstDoc : false,
      next ? lastDoc : false,
      handleData,
      `${reverseShopifyAdminId(plan.order.id)}`,
      [sortField, sortDirection],
      pageSize
    )
  }

  const handleOrderChange = async value => {
    const order = value.split(":")
    await setSortField(order[0])
    await setSortDirection(order[1])
  }

  useEffect(() => {
    handleQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan, sortDirection])

  const resourceName = {
    singular: "log",
    plural: "logs",
  }

  const renderItem = item => <LogsItem item={item} condensed />

  return (
    <div style={{ marginBottom: 20 }}>
      <Card>
        <Card.Header title="Recent Activity History"></Card.Header>
        <ResourceList
          items={items}
          resourceName={resourceName}
          renderItem={renderItem}
          sortValue={`${sortField}:${sortDirection}`}
          sortOptions={[
            { label: "Newest first", value: "createdAt:desc" },
            { label: "Oldest first", value: "createdAt:asc" },
          ]}
          onSortChange={handleOrderChange}
          // filterControl={filterControl}
          // promotedBulkActions={bulkActions}
          // selectedItems={selectedItems}
          // onSelectionChange={onSelectedChange}
          // selectable={true}
          loading={loading}
          emptyState={
            <EmptyState>
              <p>{`View and track activity history on the payment plan.`}</p>
            </EmptyState>
          }
          selectable={false}
        />
      </Card>
      <br />
      <LogsPagination showPrev={showPrev && !loading && items.length} showNext={showNext && !loading && items.length} onPagination={handleQuery} />
    </div>
  )
}
