import React, { useState, useCallback } from "react"

import { Card, FormLayout, TextField, Select, InlineError, TextStyle, TextContainer, Modal, List } from "@shopify/polaris"

export const PaymentPlanDiscountCard = ({ plan, onUpdateDiscounts, isUpdating }) => {
  const currentDiscount = plan.subscription?.discounts.length > 0 ? plan.subscription.discounts[0] : null
  const remainingBillingCycles = plan.subscription.numberOfBillingCycles - plan.subscription.currentBillingCycle

  const [modalActive, setModalActive] = useState(false)
  const [amount, setAmount] = useState(currentDiscount ? currentDiscount.amount : null)
  const [amountError, setAmountError] = useState(false)
  const [amountErrorMessage, setAmountErrorMessage] = useState(null)
  const [numberOfCycles, setNumberOfCycles] = useState(currentDiscount ? `${currentDiscount.numberOfBillingCycles}` : "")
  const [numberOfCyclesError, setNumberOfCyclesError] = useState(false)

  const handleModalChange = useCallback(() => setModalActive(false), [])

  const handleAmountChange = useCallback(
    value => {
      if (!isNaN(value)) {
        setAmount(value)
      }

      if (parseFloat(value) > parseFloat(plan?.subscription?.price)) {
        setAmountError(true)
        setAmountErrorMessage(`Must less than or equal to ${plan?.subscription?.price}`)
      } else {
        setAmountError(false)
        setAmountErrorMessage(null)
      }
    },
    [plan]
  )

  const handleNumberOfCyclesUpdate = useCallback(value => {
    setNumberOfCyclesError(false)
    setNumberOfCycles(value)
  }, [])

  const handleClickAddDiscount = () => {
    let hasErrors = false
    if (!amount || isNaN(amount) || amount === "") {
      hasErrors = true
      setAmountError(true)
    }

    if (numberOfCycles === "") {
      hasErrors = true
      setNumberOfCyclesError(true)
    }

    if (hasErrors) {
      return false
    }

    setModalActive(true)
  }

  const handleAddDiscountToPlan = () => {
    const discounts = {
      type: "add",
      numberOfBillingCycles: numberOfCycles,
      amount: amount,
    }

    onUpdateDiscounts({ discounts }).then(res => {
      setModalActive(false)
    })
  }

  const getOptions = () => {
    const options = []
    for (let i = 1; i <= remainingBillingCycles; i++) {
      options.push({ label: `${i} payment${i !== 1 ? "s" : ""}`, value: `${i}` })
    }
    return options
  }

  const getHelpMessage = () => {
    if (amount && numberOfCycles) {
      return `${numberOfCycles} payment${parseInt(numberOfCycles) !== 1 ? "s" : ""} of $${Number(amount).toFixed(2)} off`
    }

    return null
  }

  const getTotalAmountMessage = () => {
    const totalAmount = parseFloat(Number(amount).toFixed(2)) * parseInt(numberOfCycles)
    return `Total discount amount is $${!isNaN(totalAmount) ? totalAmount.toFixed(2) : "0.00"}`
  }

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Card
          primaryFooterAction={
            !currentDiscount
              ? {
                  disabled: currentDiscount,
                  content: "Add Discount",
                  onAction: handleClickAddDiscount,
                  loading: isUpdating,
                }
              : null
          }
        >
          <Card.Header title="Add Discount"></Card.Header>
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  id={`selectField`}
                  disabled={currentDiscount}
                  label="Number of payments"
                  placeholder="Select payment(s)"
                  options={getOptions()}
                  value={numberOfCycles}
                  onChange={handleNumberOfCyclesUpdate}
                  error={numberOfCyclesError}
                  helpText={<span>{getHelpMessage()}</span>}
                />
                <TextField
                  id={`amountField`}
                  error={amountError}
                  disabled={currentDiscount}
                  type="number"
                  label="Amount for each payment"
                  value={amount}
                  onChange={handleAmountChange}
                  helpText={amountErrorMessage ? <InlineError message={amountErrorMessage} fieldID="amountField" /> : null}
                  autoComplete="off"
                />
              </FormLayout.Group>
            </FormLayout>
            <br />
            {amount && numberOfCycles ? <TextStyle variation="strong">{getTotalAmountMessage()}</TextStyle> : null}
          </Card.Section>
        </Card>
      </div>

      <Modal
        open={modalActive}
        onClose={handleModalChange}
        title="Add discount to Payment Plan?"
        primaryAction={{
          content: "Continue",
          onAction: handleAddDiscountToPlan,
          loading: isUpdating,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleModalChange,
          },
        ]}
      >
        <Modal.Section>
          <Card.Section title="Recurring discount">
            <List>
              <List.Item>{getHelpMessage()}</List.Item>
            </List>
          </Card.Section>
          <Card.Section>
            <TextContainer>
              <TextStyle variation="strong">{`The discount will be added to recurring payments and this cannot be undone.`}</TextStyle>
            </TextContainer>
          </Card.Section>
        </Modal.Section>
      </Modal>
    </>
  )
}
