import React, { useState, useEffect, useCallback } from "react"
import { Card, List, ChoiceList, Badge } from "@shopify/polaris"
import { ResourcePicker } from "@shopify/app-bridge-react"

export const PaymentPlanLineItems = ({ plan, onUpdateLineItems, isUpdating }) => {
  const [availableLineItems, setAvailableLineItems] = useState([])
  const [resourcePickerActive, setResourcePickerActive] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [selectedLineItemIds, setSelectedLineItemIds] = useState([])
  const [error, setError] = useState(null)

  const handleOpenResourcePicker = useCallback(() => setResourcePickerActive(true), [])
  const handleCloseResourcePicker = useCallback(() => setResourcePickerActive(false), [])
  const handleSelectedLineItemsChange = useCallback(value => setSelectedLineItemIds(value), [])

  useEffect(() => {
    if (plan) {
      const lineItems = plan.order.lineItems.edges.map(edge => edge.node)
      const availableLineItems = lineItems
        .reduce((result, item) => {
          if (item.refundableQuantity > 0) {
            result.push(item)
          }
          return result
        }, [])
        .map(lineItem => {
          const eligible = checkEligibleItem(lineItem)
          return {
            label: (
              <p>
                {`${lineItem.refundableQuantity} × ${lineItem.variant?.displayName || lineItem.title}
            `}
                {eligible ? <Badge status={"attention"}>{`onedayPAY Eligible`}</Badge> : ""}
              </p>
            ),
            value: lineItem.id,
            eligible: eligible,
          }
        })
      setAvailableLineItems(availableLineItems)
    }
  }, [plan])

  useEffect(() => {
    setSelectedLineItemIds(availableLineItems.map(lineItem => lineItem.value))
  }, [availableLineItems])

  useEffect(() => {
    if (!isUpdating) {
      setSelectedLineItemIds(availableLineItems.map(lineItem => lineItem.value))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdating])

  useEffect(() => {
    let error = "Must have at least one eligible onedayPAY item"
    for (const item of availableLineItems) {
      if (item.eligible && selectedLineItemIds.includes(item.value)) {
        error = null
      }
    }

    error = selectedLineItemIds.length === 0 ? "Must select at least one item" : error
    setError(error)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLineItemIds])

  const handleAddProduct = selectPayload => {
    setSelectedProducts([...selectedProducts, ...selectPayload.selection])
    setResourcePickerActive(false)
  }

  const handleUpdateLineItems = () => {
    let variants = []
    for (const product of selectedProducts) {
      variants = variants.concat(product.variants)
    }

    const removedLineItems = plan.order.lineItems.edges.map(edge => edge.node).filter(lineItem => !selectedLineItemIds.includes(lineItem.id))

    onUpdateLineItems({ variants, lineItems: removedLineItems }).then(res => {
      if (res) {
        setSelectedProducts([])
      }
    })
  }

  const checkEligibleItem = lineItem => {
    return lineItem.product?.tags?.find(tag => tag.includes("payment-plan:"))
  }

  return (
    <div style={{ marginBottom: 20 }}>
      <Card
        secondaryFooterActions={[{ content: "Add Item", onAction: handleOpenResourcePicker, disabled: plan.status === "Canceled" }]}
        primaryFooterAction={{
          content: "Update",
          onAction: handleUpdateLineItems,
          disabled: error || plan.status === "Canceled" || (!selectedProducts.length && selectedLineItemIds.length === availableLineItems.length),
          loading: isUpdating,
        }}
      >
        <Card.Header title="Add / Remove Order Line Items"></Card.Header>
        <Card.Section title="Items">
          <ChoiceList
            allowMultiple
            title=""
            choices={availableLineItems}
            selected={selectedLineItemIds}
            onChange={handleSelectedLineItemsChange}
            disabled={plan.status === "Canceled" || availableLineItems.length < 2}
            error={error ? error : ""}
          />
        </Card.Section>
        {selectedProducts.length > 0 ? (
          <Card.Section
            title="New Items"
            actions={[
              {
                content: "Clear All",
                onAction: () => {
                  setSelectedProducts([])
                },
                destructive: true,
              },
            ]}
          >
            <List>
              {selectedProducts.map((product, index) =>
                product.variants.map(variant => (
                  <List.Item key={`${variant.id}-${index}`}>{`1 × ${
                    variant.title !== "Default Title" ? variant.displayName : product.title
                  }`}</List.Item>
                ))
              )}
            </List>
          </Card.Section>
        ) : null}
      </Card>

      <ResourcePicker
        resourceType="Product"
        open={resourcePickerActive}
        onCancel={handleCloseResourcePicker}
        onSelection={handleAddProduct}
        showVariants={true}
        allowMultiple={true}
      />
    </div>
  )
}
