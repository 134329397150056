import React from "react"

import { Card } from "@shopify/polaris"

export const PaymentPlanOrderDetailsCard = ({ plan }) => {
  return (
    <div style={{ marginBottom: 20 }}>
      <Card>
        <Card.Section title="Next Payment Amount">
          <p>{`${
            plan.status === "Canceled"
              ? "Cancelled"
              : plan.status === "Expired"
              ? "Completed"
              : plan.subscription?.nextBillAmount && plan.subscription?.nextBillAmount !== ""
              ? `$${plan.subscription.nextBillAmount}`
              : `N/A`
          }`}</p>
        </Card.Section>
        <Card.Section title="Next Payment Date">
          <p>{`${
            plan.status === "Canceled"
              ? "Cancelled"
              : plan.status === "Expired"
              ? "Completed"
              : plan.subscription?.nextBillingDate && plan.subscription?.nextBillingDate !== ""
              ? `${plan.subscription.nextBillingDate}`
              : `N/A`
          }`}</p>
        </Card.Section>
      </Card>
    </div>
  )
}
