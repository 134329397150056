import React from "react"
import { Layout } from "@shopify/polaris"

import { PaymentPlanCustomerCard } from "./PaymentPlanCustomerCard"
import { PaymentPlanOrderCard } from "./PaymentPlanOrderCard"
import { PaymentPlanOrderDetailsCard } from "./PaymentPlanOrderDetailsCard"
import { PaymentPlanLineItems } from "./PaymentPlanLineItems"
import { PaymentPlanDiscountCard } from "./PaymentPlanDiscountCard"
import { PaymentPlanTransactionList } from "./PaymentPlanTransactionList"
import { PaymentPlanDateCard } from "./PaymentPlanDateCard"
import { PaymentPlanActivityCard } from "./PaymentPlanActivityCard"
import { PaymentPlanBalanceCard } from "./PaymentPlanBalanceCard"
import { PaymentPlanBillingCycleCard } from "./PaymentPlanBillingCycleCard"

export const PaymentPlanForm = ({ plan, onUpdatePlan, isUpdating, redirect, subscriptionUrl, transactionUrl, firebase, shop }) => {
  return (
    <Layout>
      <Layout.Section>
        <PaymentPlanBalanceCard plan={plan} />
        <PaymentPlanTransactionList plan={plan} transactionUrl={transactionUrl} />
        <PaymentPlanLineItems plan={plan} onUpdateLineItems={onUpdatePlan} isUpdating={isUpdating} />
        <PaymentPlanDiscountCard plan={plan} onUpdateDiscounts={onUpdatePlan} isUpdating={isUpdating} />
        <PaymentPlanDateCard plan={plan} onUpdateDate={onUpdatePlan} isUpdating={isUpdating} />
        <PaymentPlanBillingCycleCard plan={plan} onUpdateBillingCycle={onUpdatePlan} isUpdating={isUpdating} />
        <PaymentPlanActivityCard plan={plan} firebase={firebase} shop={shop} />
      </Layout.Section>

      <Layout.Section secondary>
        <PaymentPlanCustomerCard plan={plan} redirect={redirect} />
        <PaymentPlanOrderCard plan={plan} redirect={redirect} subscriptionUrl={subscriptionUrl} />
        <PaymentPlanOrderDetailsCard plan={plan} />
      </Layout.Section>
    </Layout>
  )
}
